import React, { useEffect, useState } from "react";
import { server, config } from "../../env";
import axios from "axios";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
const FormField = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  children,
}) => (
  <div className="form-group mb-3">
    <label htmlFor={name} className="form-label">
      {/* if the label is Street then it should be  */}
      {label === "Street"
        ? "Street (maximum of 35 characters)"
        : label === "Street2"
        ? "Street 2 (optional) (maximum of 35 characters)"
        : label === "State"
        ? "State (optional)"
        : label === "Email"
        ? "Email (optional)"
        : label === "units"
        ? "Units"
        : label === "description"
        ? "Item Description"
        : label === "weight"
        ? "Total Weight (lbs)"
        : label === "value"
        ? "Total Value"
        : label === "tariff"
        ? "Tariff Number"
        : label}
    </label>
    <div className={type === "checkbox" ? "form-check" : "input-group"}>
      <input
        className={type === "checkbox" ? "form-check-input" : "form-control"}
        type={type}
        id={name}
        name={name}
        checked={type === "checkbox" ? value : undefined}
        value={type !== "checkbox" ? value : undefined}
        onChange={onChange}
      />
      {type === "checkbox" && (
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
      )}
      {children}
    </div>
  </div>
);

const handleAddHours = (time) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = time?.split(":").map(Number);

  // Calculate the new time by adding hours
  const newHours = (hours + 4) % 24; // Handle 24-hour cycle
  const newTime = `${newHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return newTime;
};

const currencyOptions = [
  "USD",
  "CAD",
  "GBP",
  "INR",
  "EUR" /* ... more currencies */,
];
const CreateOrder = ({ changeTab }) => {
  const [formData, setFormData] = useState({
    // Sender Info
    fromName: "",
    fromStreet: "",
    fromStreet2: "",
    fromCity: "",
    fromState: "",
    fromZip: "",
    fromPhone: "",
    fromEmail: "",
    fromCountry: "",
    // Receiver Info
    toName: "",
    toStreet: "",
    toStreet2: "",
    toCity: "",
    toState: "",
    toZip: "",
    toPhone: "",
    toEmail: "",
    toCountry: "",
    // Package Details
    weight: "",
    length: "",
    width: "",
    height: "",

    // Default units
    weightUnit: "lbs",
    dimensionUnit: "inch",

    specialNotes: "",
    signature: false,
    pickup: false,
    pickupDate: new Date().toISOString().split("T")[0],
    pickupTime: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    pickupInfo: {
      name: "",
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      country: "",
    },
  });

  const [loader, setLoader] = useState("");
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [shipmentPurpose, setShipmentPurpose] = useState("Gift");
  const [currency, setCurrency] = useState("USD");

  const [price, setPrice] = useState("");

  useEffect(() => {
    readPrice();
  }, []);

  const handleItemChange = (index, event) => {
    const newInvoiceItems = [...invoiceItems];
    newInvoiceItems[index][event.target.name] = event.target.value;
    setInvoiceItems(newInvoiceItems);
  };

  const addItem = () => {
    if (invoiceItems.length < 10) {
      setInvoiceItems([
        ...invoiceItems,
        { units: "", description: "", weight: "", value: "", tariff: "" },
      ]);
    }
  };

  const deleteItem = (index) => {
    const newInvoiceItems = invoiceItems.filter((_, i) => i !== index);
    setInvoiceItems(newInvoiceItems);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith("pickupInfo")) {
      setFormData({
        ...formData,
        pickupInfo: {
          ...formData.pickupInfo,
          [name.replace("pickupInfo.", "")]:
            type === "checkbox" ? checked : value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const toggleWeightUnit = () => {
    setFormData({
      ...formData,
      weightUnit: formData.weightUnit === "kg" ? "lbs" : "kg",
      dimensionUnit: formData.dimensionUnit === "cm" ? "in" : "cm",
    });
  };

  const handleSubmit = async (e) => {
    setLoader(<Loader />);
    e.preventDefault();

    // if enter is pressed dont submit the form
    if (e.key === "Enter") {
      return;
    }

    const completeFormData = {
      ...formData,
      invoiceItems,
      shipmentPurpose,
      currency,
    };

    await axios
      .post(`${server}/api/v1/fed/create-order`, completeFormData, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        changeTab("orders");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
      });

    console.log("Complete Form Data:", completeFormData);
  };

  const readPrice = async () => {
    await axios
      .get(`${server}/api/v1/fed/read-price`, config)
      .then((res) => {
        setPrice(res.data.price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderToggle = (toggleFunction, currentUnit) => (
    <div className="input-group-append">
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={toggleFunction}
      >
        {currentUnit.toUpperCase()}
      </button>
    </div>
  );
  // Dynamically generate form fields
  const renderFormFields = (section) => {
    return Object.entries(formData)
      .filter(([key]) => key.startsWith(section))
      .map(([key, value]) => (
        <FormField
          key={key}
          label={key
            .replace(section, "")
            .replace(/([A-Z])/g, " $1")
            .trim()} // Add spaces before capitals and trim
          name={key}
          value={value}
          onChange={handleChange}
          type={
            key.includes("Email")
              ? "email"
              : key.includes("Phone")
              ? "number"
              : "text"
          }
        />
      ));
  };

  const renderPickupInfoFields = () => {
    return Object.entries(formData.pickupInfo).map(([key, value]) => (
      <FormField
        key={key}
        label={
          key
            .replace(/([A-Z])/g, " $1")
            .trim()
            .charAt(0)
            .toUpperCase() +
          key
            .replace(/([A-Z])/g, " $1")
            .trim()
            .slice(1)
        }
        name={`pickupInfo.${key}`}
        value={value}
        onChange={handleChange}
        type="text" // All fields are text for simplicity, adjust as needed
      />
    ));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-4">
        {/* Sender Information */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title text-center">
                Sender Information (Use Fake Info)
              </h4>
            </div>
            <div className="card-body">{renderFormFields("from")}</div>
          </div>
        </div>
        {/* Receiver Information */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title text-center">Receiver Information</h4>
            </div>
            <div className="card-body">{renderFormFields("to")}</div>
          </div>
        </div>
        {/* Package Details */}
        <div className="col-md-12">
          <div className="card mt-4">
            <div className="card-header">
              <h4 className="card-title text-center">Package Details</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <FormField
                    label="Weight"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.weightUnit)}
                  </FormField>
                </div>
                <div className="col-4">
                  <FormField
                    label="Length"
                    name="length"
                    value={formData.length}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.dimensionUnit)}
                  </FormField>
                </div>
                <div className="col-4">
                  <FormField
                    label="Width"
                    name="width"
                    value={formData.width}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.dimensionUnit)}
                  </FormField>
                </div>
                <div className="col-4">
                  <FormField
                    label="Height"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.dimensionUnit)}
                  </FormField>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add-ons */}
      <div>
        <div className="row mt-3">
          <div className="col-6">
            <FormField
              label="Pickup"
              type="checkbox"
              name="pickup"
              value={formData.pickup}
              onChange={handleChange}
            />
          </div>
          <div className="col-6">
            <FormField
              label="Signature"
              type="checkbox"
              name="signature"
              value={formData.signature}
              onChange={handleChange}
            />
          </div>
        </div>

        {formData.pickup && (
          <>
            {/* Dynamic Pickup Fields */}
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="pickupDate" className="form-label">
                    Pickup Date
                  </label>
                  <div className="input-group">
                    <input
                      type="date"
                      id="pickupDate"
                      name="pickupDate"
                      value={formData.pickupDate}
                      onChange={handleChange}
                      className="form-control"
                      min={new Date().toISOString().split("T")[0]}
                      max={
                        new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 2)
                          .toISOString()
                          .split("T")[0]
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <FormField
                  label="From Pickup Time"
                  type="time"
                  name="pickupTime"
                  value={formData.pickupTime}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <FormField
                  label="To Pickup Time"
                  type="time"
                  name="pickupTime"
                  value={handleAddHours(formData.pickupTime)}
                  readOnly
                />
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-header">
                <h5 className="card-title">Pickup Info (use real info)</h5>
              </div>
              <div className="card-body">{renderPickupInfoFields()}</div>
            </div>
          </>
        )}
      </div>

      <div className="card">
        <div className="card-header">
          <h4 className="card-title text-center">Commercial Invoice Builder</h4>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="shipmentPurpose" className="form-label">
              Shipment Purpose
            </label>
            <select
              name="shipmentPurpose"
              id=""
              onChange={(e) => setShipmentPurpose(e.target.value)}
              className="form-control mb-3"
              value={shipmentPurpose}
            >
              <option value="Commercial">Commercial</option>
              <option value="Gift">Gift (recommended)</option>
              <option value="Sample">Sample</option>
              <option value="Return">Return</option>
              <option value="Repair">Repair</option>
              <option value="PersonalEffects">Personal Effects</option>
              <option value="PersonalUse">Personal Use</option>
            </select>
          </div>

          {/* Currency Dropdown */}
          <div className="form-group mb-3">
            <label htmlFor="currency" className="form-label">
              Currency
            </label>
            <select
              className="form-control"
              id="currency"
              name="currency"
              value={formData.currency}
              onChange={handleChange} // Ensure this handler updates the formData for currency
            >
              {currencyOptions.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>

          {/* Invoice Items List */}
          {invoiceItems.map((item, index) => (
            <div key={index} className="row align-items-center">
              {/* Item fields like units, description, weight, value, tariff */}
              {/* description should has more width then any other field */}

              <div className="col-1">
                <FormField
                  label="Units"
                  name="units"
                  value={item.units}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </div>
              <div className="col-3">
                <FormField
                  label="Description"
                  name="description"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </div>

              <div className="col-2">
                <FormField
                  label="Weight (total)"
                  name="weight"
                  value={item.weight}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </div>
              <div className="col-2">
                <FormField
                  label="Value (total)"
                  name="value"
                  value={item.value}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </div>

              <div className="col-2">
                <FormField
                  label="Tariff"
                  name="tariff"
                  value={item.tariff}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </div>

              {/* Render delete button */}
              <div className="col">
                <button
                  type="button"
                  onClick={() => deleteItem(index)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addItem}
            className="btn btn-info"
            disabled={invoiceItems.length >= 10}
          >
            Add Item
          </button>
        </div>
      </div>

      <div className="form-group mt-3 mb-3">
        <label htmlFor="specialNotes" className="form-label">
          Special Notes:
        </label>
        <input
          className="form-control"
          id="specialNotes"
          name="specialNotes"
          value={formData.specialNotes}
          onChange={handleChange}
          placeholder="Special Notes (optional)"
        ></input>
      </div>

      <button type="submit" className="btn btn-primary mt-3">
        Pay ${price} {loader}
      </button>
    </form>
  );
};

export default CreateOrder;
