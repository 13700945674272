import axios from "axios";
import React, { useEffect, useState } from "react";
import { config, server } from "../env";

const Announcement = (props) => {
  const [announcement, setAnnouncement] = useState("");
  const [status, setStatus] = useState(false);
  const readAnnouncement = async () => {
    await axios(server + "/api/v1/announcement/read", config)
      .then((rsp) => {
        setAnnouncement(rsp.data.announcement);
        setStatus(rsp.data.status);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    readAnnouncement();
  }, []);
  return (
    <div
      className={
        status
          ? "alert alert-icon alert-warning alert-dismissible"
          : "alert alert-icon alert-warning alert-dismissible d-none"
      }
      role="alert"
    >
      <em className="icon ni ni-alert-circle" />
      <strong>{announcement}</strong>
      <button
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          setStatus(true);
        }}
      ></button>
    </div>
  );
};

export default Announcement;
