import React, { useState } from "react";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { server, config } from "../env";
import axios from "axios";
import { toast } from "react-toastify";

const AmazonCsv = (props) => {
  const [pdfFile, setpdfFile] = useState("");
  const [exportLoader, setExportLoader] = useState("");

  const generateCsv = async (e) => {
    e.preventDefault();
    setExportLoader(<Loader />);

    const formData = new FormData();
    formData.append("pdf", e.target.pdf.files[0]);
    formData.append("fromName", e.target.name?.value);
    formData.append("fromStreet1", e.target.street1?.value);
    formData.append("fromStreet2", e.target.street2?.value);
    formData.append("fromCity", e.target.city?.value);
    formData.append("fromState", e.target.state?.value);
    formData.append("fromZip", e.target.zip?.value);
    formData.append("weight", e.target.weight?.value);

    await axios
      .post(server + "/api/v1/amazon/generate-csv", formData, config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "amazon.csv");
        document.body.appendChild(link);
        link.click();
        setExportLoader("");
      })
      .catch((err) => {
        console.log(err);
        setExportLoader("");
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />

      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Generate Amazon CSV</h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={generateCsv}>
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="default-01">
                            Select File
                          </label>
                          <div className="form-control-wrap">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="customFile"
                                name="pdf"
                                onChange={(e) => setpdfFile(e.target.files[0])}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFile"
                              >
                                {/* change to filename after selcting */}
                                {pdfFile ? pdfFile.name : "Choose file"}
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* weight */}
                        <div className="form-group">
                          <label className="form-label" htmlFor="default-06">
                            Weight
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="default-06"
                            placeholder="Enter weight"
                            name="weight"
                          />
                        </div>

                        <h5 className=" mb-2">From Details</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                className="form-label"
                                htmlFor="default-01"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="default-01"
                                placeholder="Enter name"
                                name="name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                className="form-label"
                                htmlFor="default-01"
                              >
                                Street 1
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="default-01"
                                placeholder="Enter street 1"
                                name="street1"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                className="form-label"
                                htmlFor="default-01"
                              >
                                Street 2 (Optional)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="default-01"
                                placeholder="Enter street 2"
                                name="street2"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                className="form-label"
                                htmlFor="default-01"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="default-01"
                                placeholder="Enter city"
                                name="city"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                className="form-label"
                                htmlFor="default-01"
                              >
                                State
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="default-01"
                                placeholder="Enter state"
                                name="state"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label
                                className="form-label"
                                htmlFor="default-01"
                              >
                                Zip
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="default-01"
                                placeholder="Enter zip"
                                name="zip"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            Generate CSV {exportLoader}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmazonCsv;
