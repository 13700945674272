import React, { useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { useEffect } from "react";

const Faq = (props) => {
  const [faqs, setFaqs] = useState([]);

  const readFaq = async () => {
    await axios
      .get(server + "/api/v1/faq/read", config)
      .then((res) => {
        setFaqs(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readFaq();
  }, []);
  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">
                              Frequently Asked Questions
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div id="accordion" className="accordion">
                          {faqs.map((faq, index) => (
                            <div className="accordion-item">
                              <a
                                href="#"
                                className="accordion-head"
                                data-toggle="collapse"
                                data-target={`#faq${index}`}
                              >
                                <h6 className="title">{faq.question}</h6>
                                <span className="accordion-icon" />
                              </a>
                              <div
                                className="accordion-body collapse hide"
                                id={`faq${index}`}
                                data-parent="#accordion"
                              >
                                <div className="accordion-inner">
                                  <p>{faq.answer}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
