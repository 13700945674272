import React, { useState, useRef } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const recaptchaRef = useRef(null);

  const login = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const data = {
      tgId: e.target.tgId.value,
      password: e.target.password.value,
      captchaToken,
    };

    await axios
      .post(server + "/api/v1/auth/login", data)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        Cookies.set("token", res.data.accessToken);
        window.location.href = "/";
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
        setLoader("");
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  {/* <img
                    className="logo-dark logo-img logo-img-lg"
                    src="/assets/logo-2.png"
                    alt="logo-dark"
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  /> */}
                </a>
              </div>
              <div className="card">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Sign-In</h4>
                      <div className="nk-block-des"></div>
                    </div>
                  </div>
                  {message}
                  <form onSubmit={login}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="default-01">
                        Telegram Username
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">@</span>
                        <input
                          type="text"
                          className="form-control form-control-lg input-group-append"
                          id="default-01"
                          name="tgId"
                          placeholder="Enter your telegram username"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <span
                          className="form-icon form-icon-right "
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <em className="passcode-icon icon-show icon ni ni-eye" />
                          ) : (
                            <em className="passcode-icon icon-show icon ni ni-eye-off" />
                          )}
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control form-control-lg"
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-primary btn-block"
                        type="submit"
                      >
                        Sign in {loader}
                      </button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    {" "}
                    New on our platform?{" "}
                    <Link to="/register">Create an account</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>

      {/* main @e */}
      <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
    </div>
  );
};

export default Login;
