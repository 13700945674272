import React, { useState, useRef } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const recaptchaRef = useRef(null);

  const register = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const data = {
      username: e.target.name.value,
      tgId: e.target.tgId.value,
      password: e.target.password.value,
      captchaToken,
    };

    await axios
      .post(server + "/api/v1/auth/register", data)
      .then((res) => {
        setLoader("");
        toast.success("Account created successfully, please login");
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  {/* <img
                    className="logo-dark logo-img logo-img-lg"
                    src="/assets/logo-2.png"
                    alt="logo-dark"
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  /> */}
                </a>
              </div>
              <div className="card">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Sign-Up</h4>
                      <div className="nk-block-des"></div>
                    </div>
                  </div>
                  {message}
                  <form onSubmit={register}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          Name
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="default-01"
                        name="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="default-01">
                        Telegram Username
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">@</span>
                        <input
                          type="text"
                          className="form-control form-control-lg input-group-append"
                          id="default-01"
                          name="tgId"
                          placeholder="Enter your telegram username"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                        />
                      </div>
                    </div>

                    {/* write a note that by signingup you are agreeing to our tos */}

                    <div className="text-center mb-3">
                      <span htmlFor="kt_login_remember">
                        By Signing up I agree to the{" "}
                        <Link to="/tos">Terms of Service</Link>{" "}
                      </span>
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-primary btn-block"
                        type="submit"
                      >
                        Sign Up {loader}
                      </button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    {" "}
                    Already have an account? <Link to="/">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
      <ToastContainer />
      {/* main @e */}
    </div>
  );
};

export default Register;
