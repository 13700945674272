import React, { useState, useEffect } from "react";
import { server } from "../env";
import axios from "axios";

const Tos = () => {
  const [tos, setTos] = useState("");

  useEffect(() => {
    readTos();
  }, []);

  const readTos = async () => {
    await axios
      .get(server + "/api/v1/tos/read")
      .then((res) => {
        setTos(res.data.tos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="nk-app-root">
      <div className="nk-main ">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-block  p-4">
              <h1 className="text-center">Terms of Service</h1>
              {/*  */}

              {/* dangeroues html */}
              <div
                dangerouslySetInnerHTML={{
                  __html: tos,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tos;
